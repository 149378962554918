import React, { useEffect } from "react";
import { Swiper } from "swiper";
import "../assets/css/plugins.css";
import "../assets/css/style.css";
import { Link } from "react-router-dom";
import VideoPlayer from "./OnlineCourses/VideoPlayer";

export interface BookInterface {
  id: number;
  link: string;
  imgSrc: string;
  title: string;
  content: string;
  price: number;
  currency: string;
}
export const Home = ({ books }: { books: BookInterface[] }) => {
  useEffect(() => {
    var carousel = document.querySelectorAll(".swiper-container");
    for (var i = 0; i < carousel.length; i++) {
      var slider1 = carousel[i];
      slider1.classList.add("swiper-container-" + i);
      var controls = document.createElement("div");
      controls.className = "swiper-controls";
      var pagi = document.createElement("div");
      pagi.className = "swiper-pagination";
      var navi = document.createElement("div");
      navi.className = "swiper-navigation";
      var prev = document.createElement("div");
      prev.className = "swiper-button swiper-button-prev";
      var next = document.createElement("div");
      next.className = "swiper-button swiper-button-next";
      slider1.appendChild(controls);
      controls.appendChild(navi);
      navi.appendChild(prev);
      navi.appendChild(next);
      controls.appendChild(pagi);
      var sliderEffect = slider1.getAttribute("data-effect")
        ? slider1.getAttribute("data-effect")
        : "slide";
      var sliderItems = slider1.getAttribute("data-items")
        ? slider1.getAttribute("data-items")
        : 3; // items in all devices
      var sliderItemsXs = slider1.getAttribute("data-items-xs")
        ? slider1.getAttribute("data-items-xs")
        : 1; // start - 575
      var sliderItemsSm = slider1.getAttribute("data-items-sm")
        ? slider1.getAttribute("data-items-sm")
        : Number(sliderItemsXs); // 576 - 767
      var sliderItemsMd = slider1.getAttribute("data-items-md")
        ? slider1.getAttribute("data-items-md")
        : Number(sliderItemsSm); // 768 - 991
      var sliderItemsLg = slider1.getAttribute("data-items-lg")
        ? slider1.getAttribute("data-items-lg")
        : Number(sliderItemsMd); // 992 - 1199
      var sliderItemsXl = slider1.getAttribute("data-items-xl")
        ? slider1.getAttribute("data-items-xl")
        : Number(sliderItemsLg); // 1200 - end
      var sliderItemsXxl = slider1.getAttribute("data-items-xxl")
        ? slider1.getAttribute("data-items-xxl")
        : Number(sliderItemsXl); // 1500 - end
      var sliderSpeed = slider1.getAttribute("data-speed")
        ? slider1.getAttribute("data-speed")
        : 500;
      var sliderAutoPlay = slider1.getAttribute("data-autoplay") !== "false";
      var sliderAutoPlayTime = slider1.getAttribute("data-autoplaytime")
        ? slider1.getAttribute("data-autoplaytime")
        : 5000;
      var sliderAutoHeight = slider1.getAttribute("data-autoheight") === "true";
      var sliderMargin = slider1.getAttribute("data-margin")
        ? slider1.getAttribute("data-margin")
        : 30;
      var sliderLoop = slider1.getAttribute("data-loop") === "true";
      var sliderCentered = slider1.getAttribute("data-centered") === "true";
      var swiper = slider1.querySelector(".swiper:not(.swiper-thumbs)");
      var swiperTh = slider1.querySelector(".swiper-thumbs");
      var sliderTh = new Swiper(swiperTh, {
        slidesPerView: 5,
        spaceBetween: 10,
        loop: false,
        threshold: 2,
        slideToClickedSlide: true,
      });
      if (slider1.getAttribute("data-thumbs") === "true") {
        var thumbsInit = sliderTh;
        var swiperMain = document.createElement("div");
        swiperMain.className = "swiper-main";
        swiper?.parentNode?.insertBefore(swiperMain, swiper);
        if (swiper) {
          swiperMain.appendChild(swiper);
        }
        slider1.removeChild(controls);
        swiperMain.appendChild(controls);
      } else {
        var thumbsInit = null;
      }
      var slider = new Swiper(swiper, {
        on: {
          beforeInit: function () {
            if (
              slider1.getAttribute("data-nav") !== "true" &&
              slider1.getAttribute("data-dots") !== "true"
            ) {
              controls.remove();
            }
            if (slider1.getAttribute("data-dots") !== "true") {
              pagi.remove();
            }
            if (slider1.getAttribute("data-nav") !== "true") {
              navi.remove();
            }
          },
          init: function () {},
        },
        autoplay: {
          delay: sliderAutoPlayTime,
          disableOnInteraction: false,
        },
        speed: sliderSpeed,
        slidesPerView: sliderItems,
        loop: sliderLoop,
        centeredSlides: sliderCentered,
        spaceBetween: Number(sliderMargin),
        effect: sliderEffect,
        autoHeight: sliderAutoHeight,
        grabCursor: true,
        resizeObserver: false,
        breakpoints: {
          0: {
            slidesPerView: Number(sliderItemsXs),
          },
          576: {
            slidesPerView: Number(sliderItemsSm),
          },
          768: {
            slidesPerView: Number(sliderItemsMd),
          },
          992: {
            slidesPerView: Number(sliderItemsLg),
          },
          1200: {
            slidesPerView: Number(sliderItemsXl),
          },
          1400: {
            slidesPerView: Number(sliderItemsXxl),
          },
        },
        pagination: {
          el: carousel[i].querySelector(".swiper-pagination"),
          clickable: true,
        },
        navigation: {
          prevEl: slider1.querySelector(".swiper-button-prev"),
          nextEl: slider1.querySelector(".swiper-button-next"),
        },
        thumbs: {
          swiper: thumbsInit,
        },
      });
    }
  });
  return (
    <>
      <section className="wrapper bg-light">
        <div className="container pt-8 pt-md-14">
          <div className="row gx-lg-0 gx-xl-8 gy-10 gy-md-13 gy-lg-0 mb-7 mb-md-10 mb-lg-16 align-items-center justify-content-center">
            <div className="container mx-auto px-0">
              <div
                className="h-64 rounded-md overflow-hidden bg-cover bg-center block"
                style={{ backgroundImage: "url(./assets/img/photos/f4.webp)" }}
              >
                <div className="bg-gray-900 bg-opacity-50 flex items-center h-full backdrop-blur-md">
                  <div className="max-w-xl block px-5  ">
                    <h2
                      className="display-1 mb-5 animate__slideInDown text-base sm:text-2xl text-white font-semibold"
                      style={{
                        animationDuration: "700ms",
                        animationDelay: "600ms",
                      }}
                    >
                      Intelligence Surveillance Academy
                    </h2>
                    {/*<p className="mt-2 text-gray-400">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Tempore facere provident molestias ipsam sint voluptatum pariatur.</p>*/}
                    <p
                      className="lead lh-sm mb-7 px-md-10 px-lg-0 animate__slideInDown mt-2 text-white text-sm sm:text-2xl "
                      style={{
                        animationDuration: "700ms",
                        animationDelay: "900ms",
                      }}
                    >
                      We bring information to make our students' life easier.
                    </p>
                    <div
                      className="d-flex justify-content-start flex-wrap gap-3 sm:justify-content-center justify-content-lg-start animate__slideInDown"
                      style={{
                        animationDuration: "700ms",
                        animationDelay: "900ms",
                      }}
                      data-group="page-title-buttons"
                      data-delay={900}
                    >
                      <span
                        className="animate__slideInDown"
                        style={{
                          animationDuration: "700ms",
                          animationDelay: "1200ms",
                        }}
                      >
                        <Link
                          to="/isa-all-training-courses"
                          className="btn btn-md btn-primary rounded-pill"
                        >
                          All Training Courses
                        </Link>
                      </span>
                      <span
                        className="animate__slideInDown"
                        style={{
                          animationDuration: "700ms",
                          animationDelay: "1500ms",
                        }}
                      >
                        <Link
                          to="#latest-courses"
                          className="btn btn-md btn-primary rounded-pill "
                        >
                          Latest Courses
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:mx-auto xl:container py-6 2xl:px-0 px-4 mt-0">
              <div className="lg:flex items-center justify-between">
                <div className=" lg:w-1/2 w-full lg:mb-0 mb-6">
                  <p className="text-primary leading-4 text-gray-600">
                    Choose your book
                  </p>
                  <h1
                    role="heading"
                    className="md:text-5xl text-3xl font-bold leading-10 mt-3 text-gray-800"
                  >
                   The Geru Method - BOOKS
                  </h1>
                  <p
                    role="contentinfo"
                    className="text-primary leading-5 mt-5 text-gray-600"
                  >
                    Intelligence Surveillance Academy company offers a series of
                    covert surveillance books at various levels of complexity.
                    They provide valuable knowledge and insights into the field
                    of covert surveillance.{" "}
                  </p>
                </div>
                <div
                  className="xl:w-1/2 lg:w-7/12 relative w-full lg:mt-0 md:px-8"
                  role="list"
                >
                  <img
                    src="https://i.ibb.co/0n6DSS3/bgimg.png"
                    className="absolute w-full -ml-12 mt-15"
                    alt="background circle images"
                  />
                  <div
                    role="listitem"
                    onClick={() => {
                      window.location.href = books[0].link;
                    }}
                    className="hover:bg-[#e3e3e3] bg-[#ffffff] cursor-pointer  w-[98%] ml-auto border-1 border-primary shadow rounded-lg p-4 relative z-30"
                  >
                    <div className="flex items-center justify-between">
                      <div>
                        <h2 className="text-2xl font-semibold leading-6 text-gray-800">
                          {books[0].title}
                        </h2>
                        <p className="text-xl mt-2 font-semibold leading-6 text-[#7f6a00]">
                          {books[0].price} {books[0].currency}
                        </p>
                      </div>
                      <img
                        src={"../assets/img/photos/book1-bg.webp"}
                        alt={books[0].title}
                        width={"120px"}
                      />
                    </div>
                  </div>
                  <div
                    role="listitem"
                    onClick={() => {
                      window.location.href = books[2].link;
                    }}
                    className="hover:bg-[#e3e3e3] bg-[#ffffff] cursor-pointer border-1 border-primary shadow rounded-lg mt-3 flex relative z-30"
                  >
                    <div className="w-2.5  h-auto bg-primary rounded-tl-md rounded-bl-md" />
                    <div className="w-full px-4 py-6">
                      <div className="flex items-center justify-between">
                        <div>
                          <h2 className="text-2xl font-semibold leading-6 text-gray-800">
                            {books[2].title}
                          </h2>
                          <p className="text-xl mt-2 font-semibold leading-6 text-[#7f6a00]">
                            {books[2].price} {books[2].currency}
                          </p>
                        </div>
                        <img
                          src={"../assets/img/photos/booksBoth-bg.webp"}
                          alt={books[2].title}
                          width={"180px"}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    role="listitem"
                    onClick={() => {
                      window.location.href = books[1].link;
                    }}
                    className="hover:bg-[#e3e3e3] bg-[#ffffff] border-1 border-primary w-[98%] ml-auto cursor-pointer shadow rounded-lg p-4 relative z-30 mt-7"
                  >
                    <div className="flex items-center justify-between">
                      <div>
                        <h2 className="text-2xl font-semibold leading-6 text-gray-800">
                          {books[1].title}
                        </h2>
                        <p className="text-xl mt-2 font-semibold leading-6 text-[#7f6a00]">
                          {books[1].price} {books[1].currency}
                        </p>
                      </div>
                      <img
                        src={"../assets/img/photos/book2-bg.webp"}
                        alt={books[1].title}
                        width={"120px"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row gy-10 gy-sm-13 gx-lg-3 px-0 md:px-4 mt-4 align-items-center justify-content-between ">
              <div className="col-lg-5 mt-3">
                <h2 className="display-4 mb-3">Colonel.r. Doru Geru</h2>
                <p className="lead fs-lg mb-6">
                  After nearly 32 years of working in covert surveillance for
                  organized crime and corporate, and 1,400 judiciary actions
                  performed, expert in this field, trainer, consultant, lawyer,
                  licensed private detective, licensed security specialist and
                  data protection, he focuses today on training activities to
                  teach new specialists the art of surveillance.
                </p>
                <p>
                  He is also in charge of organizing from scratch surveillance
                  departments for judicial, private and other intelligence
                  clients. At the same time, he trains close protection teams to
                  carry out this work while applying new sciences, including
                  covert surveillance. Colonel.r Geru Doru is qualified in
                  conducting covert actions following the exams given at the
                  completion of “A.I. CUZA” Police Academy of Bucharest - 5
                  years, Postgraduate studies in covert surveillance at “A.I.
                  CUZA “Police Academy of Bucharest – 1 year, Postgraduate
                  studies in forensics - 1 year at “A.I. CUZA Police” Academy of
                  Bucharest and Master in criminal sciences - 2 years. And, he
                  currently participates in undercover investigations for
                  private clients with his team.
                </p>
              </div>
              <div className="col-md-8 col-lg-6 mx-auto position-relative mt-3">
                <div
                  className="shape bg-line red rounded-circle rellax w-18 h-18"
                  data-rellax-speed={1}
                  style={{ top: "-2.2rem", left: "-2.4rem" }}
                />
                <div
                  className="shape rounded bg-soft-primary rellax d-md-block"
                  data-rellax-speed={0}
                  style={{
                    bottom: "-1.8rem",
                    right: "-1.5rem",
                    width: "85%",
                    height: "90%",
                  }}
                />
                <figure className="rounded">
                  <div className="container pb-14 px-0 ">
                    <div className="container bg-[#001234] rounded-t-md py-4 flex justify-between text-white	">
                      <h1 className=" text-white text-6  display-4  relative z-10 shadow-md">
                        Our Upcoming Courses
                      </h1>
                    </div>
                    <div className="container bg-[#ebebeb] rounded-b-md py-4 text-[#636363] border-b border-dotted border-gray-400">
                      <div className="mb-6">
                        <p className=" text-[#001234] fs-15 text-uppercase mb-2 ">
                          &gt;DEEPENING COURSE IN COVERT SURVEILLANCE FOR
                          PROFESSIONALS
                        </p>
                        <a
                          className=" text-[#001234] fs-15 text-uppercase mb-2 "
                          href="https://geruinvestigation.co.uk/contact"
                        >
                          Please apply on the contact form.
                        </a>
                        <p className="leading-tight pl-[10px] fs-15 text-uppercase ">
                          20.02.2023-20.03.2023{" "}
                        </p>
                      </div>
                      <Link
                        to="/isa-all-training-courses"
                        className="btn btn-sm btn-primary rounded-pill me-2"
                      >
                        All Training Courses
                      </Link>
                    </div>
                  </div>
                  <img
                    src="./assets/img/photos/DG10.webp"
                    srcSet="./assets/img/photos/DG10.webp 2x"
                    alt=""
                  />
                </figure>
              </div>
            </div>

            <div
              className="col-lg-5 mt-lg-n10 text-center text-lg-start displa sm:hidden"
              data-group="page-title"
              data-delay={600}
            >
              <h1
                className="display-1 mb-5 animate__slideInDown"
                style={{ animationDuration: "700ms", animationDelay: "600ms" }}
              >
                Intelligence Surveillance Academy
              </h1>
              <p
                className="lead fs-25 lh-sm mb-7 px-md-10 px-lg-0 animate__slideInDown"
                style={{ animationDuration: "700ms", animationDelay: "900ms" }}
              >
                We bring information to make our students' life easier.
              </p>
              <div
                className="d-flex justify-content-center justify-content-lg-start animate__slideInDown"
                style={{ animationDuration: "700ms", animationDelay: "900ms" }}
                data-group="page-title-buttons"
                data-delay={900}
              >
                <span
                  className="animate__slideInDown"
                  style={{
                    animationDuration: "700ms",
                    animationDelay: "1200ms",
                  }}
                >
                  <Link
                    to="/isa-all-training-courses"
                    className="btn btn-lg btn-primary rounded-pill me-2"
                  >
                    All Training Courses
                  </Link>
                </span>
                <span
                  className="animate__slideInDown"
                  style={{
                    animationDuration: "700ms",
                    animationDelay: "1500ms",
                  }}
                >
                  <Link
                    to="#latest-courses"
                    className="btn btn-lg btn-outline-primary rounded-pill"
                  >
                    Latest Courses
                  </Link>
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 col-lg-8 col-xl-7 col-xxl-6 mx-auto text-center">
              <h2 className="fs-15 text-uppercase text-muted mb-3">
                What We Do?
              </h2>
              <h3 className="display-4 mb-10">
                Intelligence Surveillance Academy is here to ensure our
                students' success and end-users’ business productivity.
              </h3>
            </div>
          </div>
          <div className="row gx-md-8 gy-8 text-center mb-14 mb-md-17">
            <div className="col-md-6 col-lg-4">
              <div className="icon btn btn-circle btn-lg btn-primary disabled mb-5">
                {" "}
                <i className="uil uil-phone-volume" />{" "}
              </div>
              <h4>24/7 Support</h4>
              <p className="mb-3">
                We offer dependable, on-demand support options including 24/7
                technical and remote support.
              </p>
              <Link to="/terms-and-conditions" className="more hover">
                Learn More
              </Link>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="icon btn btn-circle btn-lg btn-primary disabled mb-5">
                {" "}
                <i className="uil uil-shield-exclamation" />{" "}
              </div>
              <h4>Secure Payments</h4>
              <p className="mb-3">How the payment will be made?</p>
              <Link to="/terms-and-conditions" className="more hover">
                Learn More
              </Link>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="icon btn btn-circle btn-lg btn-primary disabled mb-5">
                {" "}
                <i className="uil uil-laptop-cloud" />{" "}
              </div>
              <h4>Periodically Updates</h4>
              <p className="mb-3">
                Intelligence Surveillance Academy periodical quality updates
                help you stay informed with our current methods.{" "}
              </p>
              <Link to="/terms-and-conditions" className="more hover">
                Learn More
              </Link>
            </div>
          </div>
          <div className="container">
            <div className="row gy-10 gy-sm-13 gx-lg-3 align-items-center mb-14 mb-md-17 mb-lg-19">
              <div className="col-md-8 col-lg-6 position-relative">
                <figure className="rounded">
                  <VideoPlayer videoSrc="assets/media/surveillance-books.mp4" />
                </figure>
              </div>
              <div className="col-lg-5 offset-lg-1">
                <h2 className="fs-15 text-uppercase text-muted mb-3">
                  Who Is Intelligence Surveillance Academy?
                </h2>
                <h3 className="display-4 mb-6">
                  The company which believes in the power of creative strategy.
                </h3>
                <p className="mb-6">
                  All provided courses are taught by former policemen
                  specializing in organized crime surveillance who have been at
                  the forefront of the fight against cross-border organised
                  crime. We do not have trainers from other fields, skills or
                  specializations. We do what we know best. The fact that we are
                  here after a risky career and we have the honour to teach you
                  is the best recommendation for our trainers.
                </p>
                <p>
                  The surveillance skills you will acquire in our courses are
                  comply with the highest standards, and, upon graduation, you
                  will also be professionals in this field.
                </p>
              </div>
            </div>
            <div className="row gx-lg-8 gx-xl-12 gy-10 mb-16 mb-md-17 mb-xl-20 align-items-center">
              <div className="col-lg-4">
                <h2 className="fs-15 text-uppercase text-muted mb-3">
                  Meet the Training Team
                </h2>
                <h3 className="display-5 mb-5">
                  Save your time and money by choosing our professional team.
                </h3>
                <p>
                  We're a great team of talented coaching professionals with a
                  wide range of skills and experience. Get in touch today to
                  talk about your training needs.
                </p>
                <Link
                  to="/contact"
                  className="btn btn-primary rounded-pill mt-3"
                >
                  Contact Intelligence Surveillance Academy.
                </Link>
              </div>
              <div className="col-lg-8">
                <div
                  className="swiper-container text-center mb-6"
                  data-margin={30}
                  data-dots="true"
                  data-items-xl={3}
                  data-items-md={2}
                  data-items-xs={1}
                >
                  <div className="swiper">
                    <div className="swiper-wrapper justify-center">
                      <div className="swiper-slide">
                        <img
                          style={{ height: "200px" }}
                          className=" w-20 mx-auto  mb-4 radiu rounded-md object-cover object-top"
                          src="./assets/img/avatars/doru.webp"
                          srcSet="./assets/img/avatars/doru@2x.webp 2x"
                          alt="Colonel.r. Geru Doru"
                        />
                        <h4 className="mb-1">Colonel.r. Geru Doru</h4>
                        <div className="meta mb-2">CEO &amp; FOUNDER</div>
                        <p className="mb-2">
                          Covert operations specialist (Retired police colonel)
                        </p>
                        <nav className="nav social justify-content-center text-center mb-0">
                          <Link to="#">
                            <i className="uil uil-twitter" />
                          </Link>
                          <Link
                            target="_blank"
                            to="https://uk.linkedin.com/in/colonel-r-doru-geru-93a59010a"
                          >
                            <i className="uil uil-linkedin" />
                          </Link>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card bg-soft-primary ">
              <div className="card-body p-md-10 py-xl-16 py-xxl-20 position-relative">
                <div
                  className="position-absolute d-none d-lg-block"
                  style={{ bottom: 0, left: "10%", width: "28%", zIndex: 2 }}
                >
                  <figure>
                    <img
                      src="./assets/img/photos/gd.webp"
                      srcSet="./assets/img/photos/gd@2x.webp 2x"
                      alt=""
                    />
                  </figure>
                </div>
                <div className="row gx-md-0 gx-xl-12 text-center my-auto">
                  <div className="col-lg-7 offset-lg-5 col-xl-6 ">
                    <blockquote className="border-0 fs-lg mb-0">
                      <p>
                        Our team members, coordinated directly by director Doru
                        Geru, perform initiation, training and improvement
                        activities in the field of covert surveillance anywhere
                        in the world. We deliver surveillance and counter
                        surveillance courses tailored to the needs of our
                        corporate and private clients.
                      </p>
                      <div className="blockquote-details justify-content-center text-center">
                        <div className="info p-0">
                          <h5 className="mb-1">Colonel.r. Geru Doru</h5>
                          <div className="meta mb-0">CEO &amp; FOUNDER</div>
                        </div>
                      </div>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
