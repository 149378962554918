import React, { createContext, useEffect, useMemo, useState } from "react";
import "./App.css";
import { BookInterface, Home } from "./components/Home";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import { AboutUs } from "./components/AboutUs";
import { AllCourses } from "./components/AllCourses";
import { BodyWrapper } from "./components/BodyWrapper";
import { SurveillanceCourses } from "./components/SurveillanceCourses";
import { InvestigationsCourses } from "./components/InvestigationsCourses";

import { Services } from "./components/Services/Services";
import { IntelligenceCourses } from "./components/IntelligenceCourses";
import { IntelligenceCourse1 } from "./components/IntelligenceCourses/IntelligenceCourse1";
import { IntelligenceCourse2 } from "./components/IntelligenceCourses/IntelligenceCourse2";
import { InvestigationsCourse1 } from "./components/InvestigationsCourses/InvestigationsCourse1";
import { InvestigationsCourse3 } from "./components/InvestigationsCourses/InvestigationsCourse3";
import { Faq } from "./components/Faq";
import { BlogComponent } from "./components/BlogComponent";
import "@iconscout/unicons/css/line.css";
import "./assets/css/plugins.css";
import "./assets/css/style.css";
import { ContactUs } from "./components/ContactUs";
import { AllBooks } from "./components/BooksComponents/AllBooks";
import { Book } from "./components/BooksComponents/Book";
import { BooksOutlet } from "./components/BooksComponents/BooksOutlet";
import { Basket } from "./components/BasketComponent/Basket";
import { CheckoutPage } from "./components/CheckoutPage";
import { Login } from "./components/Auth/Login";
import { Register } from "./components/Auth/Register";
import { ForgotPassword } from "./components/Auth/ForgotPassword";
import { PasswordRecovery } from "./components/Auth/PasswordRecovery";
import { BasketProvider } from "./components/Context/BasketContext";
import { SurveillanceCourse6 } from "./components/SurveillanceCourses/SurveillanceCourse6";
import { SurveillanceCourse8 } from "./components/SurveillanceCourses/SurveillanceCourse8";
import { Investigations } from "./components/Services/Investigations";
import { Consultancy } from "./components/Services/Consultancy";
import { TermsAndConditions } from "./components/Legal/TermsAndConditions";
import { Disclaimer } from "./components/Legal/Disclaimer";
import { PrivacyPolicy } from "./components/Legal/PrivacyPolicy";
import { CopyrightPolicy } from "./components/Legal/CopyrightPolicy";
import { CookiePolicy } from "./components/Legal/CookiePolicy";
import BlogDetail from "./components/BlogComponent/BlogDetail";
import blogData from "./components/BlogComponent/blogData";
import { OnlineCourses } from "./components/OnlineCourses/OnlineCourses";
import { OnlineCourse } from "./components/OnlineCourses/OnlineCourse";
import { onlineCoursesData } from "./components/OnlineCourses/OnlineCoursesData";
import { UserProvider } from "./components/Context/UserContext";
import { MyCourses } from "./components/OnlineCourses/MyCourses";
import { SuccessPayment } from "./components/SuccessPayment";

function App() {
  const [basketItemsNumber, setBasketItemsNumber] = useState(0);
  const value = useMemo(
    () => ({ basketItemsNumber, setBasketItemsNumber }),
    [basketItemsNumber]
  );
  const books = [
    {
      id: 1,
      link: "/isa-books/1",
      imgSrc: "../assets/img/photos/book1.webp",
      title: "The Geru Method Vol.1",
      content:
        "<strong>The book COVERT SURVEILLANCE BEST PRACTICE - THE GERU METHODS - VOLUME 1 </strong>analyses the covert surveillance activity on 3 levels: \n" +
        "<strong>1. Theory \n" +
        "2. Application of theory at high level with minimum 5 cars and 1 VAN \n" +
        "3. Substantiation of theory</strong>, its application and the author's experience in cases of real actions explained with working variants, risk variants, risk avoidance methods, unforeseen events in the field and their remedy. No word is put outside the specialization of covert surveillance, no sentence refers to anything else. Everything is compressed to provide a huge amount of knowledge without reading things that do not concern this specialization.",
      price: 39.99,
      currency: "GBP",
    },
    {
      id: 2,
      link: "/isa-books/2",
      imgSrc: "../assets/img/photos/book2.webp",
      title: "The Geru Method Vol.2",
      content:
        "The book <strong>COVERT SURVEILLANCE BEST PRACTICE - THE GERU METHODS - VOLUME 2 </strong>is the special part of covert surveillance that, after assimilating what has been mentioned, enables us to move on to understanding high-risk actions, where working environments change, dealing with surveillance in the rural environment which still eludes the majority of the professionals, explaining how to carry out surveillance on all the continents, the specifics of each and the methods indicated for each specific environment. We also tackle almost impossible actions in seaside resorts, in the mountains where the target is very difficult to monitor.",
      price: 39.99,
      currency: "GBP",
    },
    {
      id: 3,
      link: "/isa-books/3",
      imgSrc: "../assets/img/photos/bothBooks.webp",
      title: "The Geru Method Vol.1 + Vol.2",
      content:
        "<strong>The book COVERT SURVEILLANCE BEST PRACTICE - THE GERU METHODS - VOLUME 1 </strong>analyses the covert surveillance activity on 3 levels: \n" +
        "<strong>1. Theory \n" +
        "2. Application of theory at high level with minimum 5 cars and 1 VAN \n" +
        "3. Substantiation of theory</strong>, its application and the author's experience in cases of real actions explained with working variants, risk variants, risk avoidance methods, unforeseen events in the field and their remedy. No word is put outside the specialization of covert surveillance, no sentence refers to anything else. Everything is compressed to provide a huge amount of knowledge without reading things that do not concern this specialization." +
        "\n+ \n The book <strong>COVERT SURVEILLANCE BEST PRACTICE - THE GERU METHODS - VOLUME 2 </strong>is the special part of covert surveillance that, after assimilating what has been mentioned, enables us to move on to understanding high-risk actions, where working environments change, dealing with surveillance in the rural environment which still eludes the majority of the professionals, explaining how to carry out surveillance on all the continents, the specifics of each and the methods indicated for each specific environment. We also tackle almost impossible actions in seaside resorts, in the mountains where the target is very difficult to monitor.",

      price: 70,
      currency: "GBP",
    },
  ] as BookInterface[];

  const surveillanceCourses = [
    {
      name: "DEEPENING COURSE IN COVERT SURVEILLANCE FOR PROFESSIONALS ",
      link: "/isa-surveillance-course-6",
      img: "./assets/img/photos/f7.webp",
    },
    {
      name: "DEEPENING COURSE IN COVERT SURVEILLANCE - FOR THE SPECIALIZATION OF ORGANIZED CRIME, POLICE, CLOSE PROTECTION INTEGRATED AT GOVERNMENT LEVEL",
      link: "/isa-surveillance-course-8",
      img: "./assets/img/photos/f5.webp",
    },
  ];
  const investigationCourses = [
    {
      name: "CERTIFICATE IN COVERT SURVEILLANCE OPERAȚIONS - FOR FOR STUDENTS WITH BACKGROUND IN THE MILITARY, POLICE AND OTHER RELATED SPECIALIZATIONS",
      link: "/isa-investigations-course-3",
      img: "./assets/img/photos/f8.webp",
    },
    {
      name: "DEEPENING COURSE IN COVERT SURVEILLANCE MANAGEMENT - FOR MANAGERS, DIRECTORS, TEAM LEADER",
      link: "/isa-investigations-course-1",
      img: "./assets/img/photos/f4.webp",
    },
  ];
  const intelligenceCourses = [
    {
      name: "DEEPENING COURSE IN COVERT SURVEILLANCE - Organization of departments / structures / teams / covert surveillance, intelligence for companies, corporations, enforcement organizations with Link duration of 3 months",
      link: "/isa-intelligence-course-1",
      img: "./assets/img/photos/f9.webp",
    },
    {
      name: "DEEPENING COURSE IN COVERT SURVEILLANCE - Organization of departments / structures / teams / personal protection structured in plain sight and covert with complex mixed covert surveillance procedures and intelligence",
      link: "/isa-intelligence-course-2",
      img: "./assets/img/photos/f10.webp",
    },
  ];

  const allCourses = [
    ...surveillanceCourses,
    ...investigationCourses,
    ...intelligenceCourses,
  ];
  return (
    <>
      {
        // @ts-ignore
        <BasketProvider value={value}>
          <UserProvider>
            <BrowserRouter>
              <Routes>
                <Route
                  path={"/" || "/Home"}
                  element={<BodyWrapper children={<Home books={books} />} />}
                />
                <Route
                  path={"/Home"}
                  element={<BodyWrapper children={<Home books={books} />} />}
                />
                <Route
                  path={"/login"}
                  element={<BodyWrapper children={<Login />} />}
                />

                <Route
                  path={"/register"}
                  element={<BodyWrapper children={<Register />} />}
                />
                <Route
                  path={"/forgot-password"}
                  element={<BodyWrapper children={<ForgotPassword />} />}
                />
                <Route
                  path={"/reset/:token"}
                  element={<BodyWrapper children={<PasswordRecovery />} />}
                />
                <Route
                  path={"/isa-about-us"}
                  element={<BodyWrapper children={<AboutUs />} />}
                />

                <Route
                  path={"/isa-all-training-courses"}
                  element={
                    <BodyWrapper
                      children={<AllCourses courses={allCourses} />}
                    />
                  }
                />
                <Route
                  path={"/isa-surveillance-courses"}
                  element={
                    <BodyWrapper
                      children={
                        <SurveillanceCourses courses={surveillanceCourses} />
                      }
                    />
                  }
                />
                <Route
                  path={"/isa-surveillance-course-6"}
                  element={<BodyWrapper children={<SurveillanceCourse6 />} />}
                />
                <Route
                  path={"/isa-surveillance-course-8"}
                  element={<BodyWrapper children={<SurveillanceCourse8 />} />}
                />
                <Route
                  path={"/isa-investigations-courses"}
                  element={
                    <BodyWrapper
                      children={
                        <InvestigationsCourses courses={investigationCourses} />
                      }
                    />
                  }
                />
                <Route
                  path={"/isa-investigations-course-1"}
                  element={<BodyWrapper children={<InvestigationsCourse1 />} />}
                />
                <Route
                  path={"/isa-investigations-course-3"}
                  element={<BodyWrapper children={<InvestigationsCourse3 />} />}
                />

                <Route
                  path={"/isa-intelligence-courses"}
                  element={
                    <BodyWrapper
                      children={
                        <IntelligenceCourses courses={intelligenceCourses} />
                      }
                    />
                  }
                />
                <Route
                  path={"/isa-intelligence-course-1"}
                  element={<BodyWrapper children={<IntelligenceCourse1 />} />}
                />
                <Route
                  path={"/isa-intelligence-course-2"}
                  element={<BodyWrapper children={<IntelligenceCourse2 />} />}
                />

                <Route
                  path={"isa-books"}
                  element={<BodyWrapper children={<BooksOutlet />} />}
                >
                  <Route
                    path={"all-books"}
                    element={<AllBooks books={books} />}
                  />
                  <Route path={":userId"} element={<Book books={books} />} />
                </Route>
                <Route
                  path={"isa-services"}
                  element={<BodyWrapper children={<Services />} />}
                />
                <Route
                  path={"isa-services/investigations"}
                  element={<BodyWrapper children={<Investigations />} />}
                />
                <Route
                  path={"isa-services/consultancy"}
                  element={<BodyWrapper children={<Consultancy />} />}
                />
                {/*<Route*/}
                {/*  path={"isa-faq"}*/}
                {/*  element={<BodyWrapper children={<Faq />} />}*/}
                {/*/>*/}
                <Route
                  path={"isa-blog"}
                  element={<BodyWrapper children={<BlogComponent />} />}
                />
                <Route
                  path={"contact"}
                  element={<BodyWrapper children={<ContactUs />} />}
                />
                <Route
                  path={"isa-basket"}
                  element={
                    <BodyWrapper
                      children={
                        <Basket
                          books={books}
                          onlineCourses={onlineCoursesData}
                        />
                      }
                    />
                  }
                />
                <Route
                  path={"terms-and-conditions"}
                  element={<BodyWrapper children={<TermsAndConditions />} />}
                />
                <Route
                  path={"disclaimer"}
                  element={<BodyWrapper children={<Disclaimer />} />}
                />
                <Route
                  path={"privacy-policy"}
                  element={<BodyWrapper children={<PrivacyPolicy />} />}
                />
                <Route
                  path={"copyright-policy"}
                  element={<BodyWrapper children={<CopyrightPolicy />} />}
                />
                <Route
                  path={"cookie-policy"}
                  element={<BodyWrapper children={<CookiePolicy />} />}
                />
                <Route
                  path={"checkout"}
                  element={
                    <BodyWrapper
                      children={
                        <CheckoutPage
                          books={books}
                          onlineCourses={onlineCoursesData}
                        />
                      }
                    />
                  }
                />
                <Route
                  path={"isa-online-courses"}
                  element={<BodyWrapper children={<OnlineCourses />} />}
                ></Route>
                <Route
                  path={"my-courses"}
                  element={
                    <BodyWrapper
                      children={<MyCourses onlineCourses={onlineCoursesData} />}
                    />
                  }
                ></Route>
                <Route
                  path={"payment-successful"}
                  element={<BodyWrapper children={<SuccessPayment />} />}
                ></Route>
                <Route
                  path={"isa-online-courses/:id"}
                  element={
                    <BodyWrapper
                      children={
                        <OnlineCourse onlineCourses={onlineCoursesData} />
                      }
                    />
                  }
                />
              </Routes>
            </BrowserRouter>
          </UserProvider>
        </BasketProvider>
      }
    </>
  );
}

export default App;
